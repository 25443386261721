import React from "react"
import { css } from "@emotion/core"
import Layout from "@app-components/layout/"
import Header from "@app-components/ui/header/top_bottom/"
import Feature from "@app-components/ui/feature/"
import Grid from "@app-components/ui/grid/"
import DualFeature from "@app-components/ui/dual_feature/"
import Dark from "@app-components/ui/dark/"
import LaunchFeatureImage from "@page-components/products/virtual-festival/media/launch_feature/"
import HeaderImage from "@page-components/products/virtual-festival/media/header/"
import BrandingFeatureImage from "@page-components/products/virtual-festival/media/branding_feature/"
import NativeFeatureImage from "@page-components/products/virtual-festival/media/native_feature/"
import TicketFeatureImage from "@page-components/products/virtual-festival/media/ticket_feature_virtual_festival/"
import IntegrationsFeatureImage from "@page-components/products/virtual-festival/media/integration_feature/"
import VideoDetailsImage from "@page-components/products/virtual-festival/media/video_details/"
import MyListImage from "@page-components/products/virtual-festival/media/my_list/"
import ShieldImage from "@page-components/products/virtual-festival/media/shield/"
import HEADERS from "@data/headers/"

const VirtualFestival = () => (
  <Layout
    getStartedButtons={["contact-sales"]}
    getStartedText="Contact sales to learn more."
    page="products/virtual-festival">
    <Header
      title="CineSend Virtual Festival"
      text="Launch a brand-forward virtual film festival on a trusted, secure video platform."
      noBorder
      secondaryNavigation={HEADERS.virtualFestival}
      image={<HeaderImage />}
    />
    <DualFeature
      featureOne={{
        title: "Single-ticket access",
        text: "Grant time-limited access to individual movies, playlists, or live events.",
        image: <VideoDetailsImage />
      }}
      featureTwo={{
        title: "Passholder all-access",
        text: "Offer an all-you-can-watch service where members can browse and watch anything.",
        image: <MyListImage />
      }}
    />
    <Feature
      title="Easy to launch and maintain"
      text="Launch your virtual festival in less than a day. Once set up, managing your event is simple and intuitive, even for non-technical staff."
      icon="send-white"
      image={<LaunchFeatureImage />}
    />
    <Feature
      reverse
      title="Totally custom branding"
      text="Customize the look and feel of your platform, host everything on your own web domain, and integrate functionality into your existing website to maintain an experience your patrons are already familiar with."
      icon="tag"
      image={<BrandingFeatureImage />}
    />
    <Feature
      title="Native TV apps"
      text="Reach your audience with custom Apple TV, Roku, and Firestick apps that match your brand. Deliver the same tailored user experience that your customers are used to on your web platform."
      icon="tv-play"
      image={<NativeFeatureImage />}
    />
    <Grid
      title="Tailored to Festival Needs"
      description=""
      columnWidth={3}
      small
      items={[
        {
          _id: 1,
          text: `Showcase sponsor logos across the platform and on specific films`,
          icon: "check-white"
        },
        {
          _id: 2,
          text: `Limit the number of times a film can be watched based on license restrictions`,
          icon: "check-white"
        },
        {
          _id: 3,
          text: `Add bonus content such as filmmaker introductions or recorded Q&As`,
          icon: "check-white"
        },
        {
          _id: 4,
          text: `Incorporate live Q&A or interactive conference sessions`,
          icon: "check-white"
        },
        {
          _id: 5,
          text: `Build playlists for shorts programs`,
          icon: "check-white"
        },
        {
          _id: 6,
          text: `Insert ads or pre-roll before films`,
          icon: "check-white"
        },
        {
          _id: 7,
          text: `Allow patrons to vote on films`,
          icon: "check-white"
        },
        {
          _id: 8,
          text: `Custom features to meet new needs`,
          icon: "check-white"
        }
      ]}
    />
    <Dark>
      <div className="max-width-3 mx-auto center">
        <div
          css={css`
            width: 150px;
            margin: 0 auto 30px auto;
          `}>
          <ShieldImage />
        </div>
        <h2 className="mb2">Studio-grade security</h2>
        <p className="px4 mb3">CineSend is trusted by every MPAA member studio for good reason:</p>
      </div>
      <Grid
        columnWidth={4}
        items={[
          {
            _id: 1,
            title: "Multi-DRM Enabled",
            text: `Videos are protected with Widevine, PlayReady, and FairPlay DRM technology`,
            icon: "shield-white"
          },
          {
            _id: 2,
            title: "Geoblocking",
            text: `Restrict access to content to the country, state, or even ZIP code level`,
            icon: "vpn-lock"
          },
          {
            _id: 3,
            title: "Concurrent Stream Blocking",
            text: `Prevent users from sharing tickets or login credentials with advanced protections`,
            icon: "block"
          },
          {
            _id: 4,
            title: "Forensic Watermarking",
            text: `Invisible user-specific watermarks can be enabled to trace leaks back to users`,
            icon: "screen-search-desktop"
          },
          {
            _id: 5,
            title: "Visible Watermarking",
            text: `Optionally display user-specific visible watermarks to deter piracy`,
            icon: "watermark-white"
          },
          {
            _id: 6,
            title: "Independently Audited",
            text: `Don’t just take our word for it - we’re routinely assessed by an trusted security auditor`,
            icon: "policy"
          }
        ]}
      />
    </Dark>
    <Feature
      title="Connect your existing ticketing system"
      text="Sell virtual screening tickets or festival passes on your website. Using a system you and your patrons are already familiar with, and keep all your revenue - CineSend doesn’t take a cut."
      icon="link"
      image={<TicketFeatureImage />}
    />
    <Feature
      reverse
      title="Live event integrations"
      text="Offer live events that patrons can tune into (Q&As, panels, etc), or use our zoom integration for a truly interactive experience."
      icon="ticket"
      image={<IntegrationsFeatureImage />}
    />
    <Grid
      title="Deeply customizable"
      description=""
      columnWidth={4}
      items={[
        {
          _id: 1,
          title: "Powerful analytics",
          text: `Track broad engagement trends, or drill down into user-specific analytics.`,
          icon: "trend"
        },
        {
          _id: 2,
          title: "Robust API",
          text: `Use our API to build a totally custom video experience in your own applications.`,
          icon: "gears"
        },
        {
          _id: 3,
          title: "Pass Types",
          text: `Configure different pass groups to limit access to only certain videos in your library.`,
          icon: "touch"
        },
        {
          _id: 4,
          title: "Video Insertions",
          text: `Insert intro or outro clips to select videos, or set global insertions across your library.`,
          icon: "film"
        },
        {
          _id: 5,
          title: "Playlist Support",
          text: `Combine multiple videos into playlists that can be accessed as one transaction.`,
          icon: "playlist"
        },
        {
          _id: 6,
          title: "Stunning Quality",
          text: `Adaptive-bitrate means that videos will never buffer and always look fantastic.`,
          icon: "hd-white"
        }
      ]}
    />
  </Layout>
)

VirtualFestival.propTypes = {}

export default VirtualFestival
